/* eslint-disable @typescript-eslint/indent */
/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { FormEvent, FC, useState, useEffect } from 'react'
import { useSelector } from '../../services/hooks/redux-hooks'
import { TRest } from '../../utils/typesFromBackend'
import CartTimeBlock from '../cart-time-block/cart-time-block'
import CartAdressBlock from '../cart-adress-block/cart-address-block'
import CartDeliveryBlock from '../cart-delivery-block/cart-delivery-block'
import InputName from '../ui/input-name/input-name'
import InputPhone from '../ui/input-phone/input-phone'
import TextareaComment from '../ui/textarea-comment/textarea-comment'
import style from './cart-detali.module.css'
import { useFormWithValidation } from '../../services/hooks/form-validation'
import { Link, useLocation } from 'react-router-dom'
import * as validatePhoneAPI from '../../utils/api/validate-phone-api'
import CustomInput from '../ui/custom-input/custom-input'
import { useDispatch } from 'react-redux'
import { setNotification } from '../../services/actions/notification'
import { useTextTranslation } from '../../contexts/TextTranslation'
import { useCurrentLanguage } from '../../contexts/CurrentLanguageContext'
import { CustomSpinner } from '../spinners/customSpinner/customSpinner'
import { useYandexCity } from '../../contexts/YandexCityProvider'
import { getCoordinates, getDeliveryPrice } from '../../utils/api/yandexApi'
import ModalSelectOffers from '../modal-select-offers/modal-select-offers'
import InputEmail from '../ui/input-email/input-email'
import useLinkWithParams from '../../services/hooks/useLinkWithParams'
import { updateOrderFormActionCreator } from '../../services/actions'
import { useLocalStorageState } from '../../services/hooks/useLocalStorageState'

interface ICoordinates {
  longitude: number
  latitude: number
}

interface ICartDetali {
  wantOrder: boolean
  isRemoteOrder: boolean
  rest: TRest
  activeMethodsOrderType: IActiveMethod
  setIsCodeInput: (arg0: boolean) => void
  setObjectForCreateOrder: (arg0: IObjectForCreateOrder) => void
  createOrder: (code: string, order?: IObjectForCreateOrder) => void
  setPhone: (arg: string) => void
  setUcallerId: (arg: number) => void
  isDisableButton: boolean
  setIsDisableButton: (arg0: boolean) => void
  isLoading: boolean
  setIsLoading: (arg0: boolean) => void
  offerData: any
  setOfferData: (arg: any) => void
  isyandexDelivery: boolean
}
interface IActiveMethod {
  [key: string]: boolean
}
interface IObjectForCreateOrder {
  [key: string]: any
}

export interface IBorder {
  border: string
}

const CartDetali: FC<ICartDetali> = ({
  wantOrder,
  isRemoteOrder,
  rest,
  activeMethodsOrderType,
  setIsCodeInput,
  setObjectForCreateOrder,
  createOrder,
  setPhone,
  setUcallerId,
  isDisableButton,
  setIsDisableButton,
  isLoading,
  setIsLoading,
  offerData,
  setOfferData,
  isyandexDelivery
}) => {
  const { values, handleChange, setValues } = useFormWithValidation()
  const arrayDishesInCart = useSelector((store) => store.cart.dishes)
  const [cartSum, setCartSum] = useState(0)
  const [generalSum, setGeneralSum] = useState(0)
  const [activeMethods, setactiveMethods] = useLocalStorageState<IActiveMethod>(
    'paymentMethods',
    {}
  )
  const [hidePayment, setHidePayment] = useState(false)
  // начальная дата доствки
  const [startDate, setStartDate] = useState<Date | null>(() => {
    const savedDate = localStorage.getItem('startDate')
    return savedDate ? new Date(savedDate) : null
  })
  const [currentCityId, setCurrentCityId] = useState('')
  const [currentStreet, setCurrentStreet] = useState('')
  const [deliverySum, setDeliverySum] = useLocalStorageState('deliverySum', 0)
  const [check, setCheck] = useLocalStorageState<boolean>(
    'deliveryTimeCheckbox',
    true
  )
  const [validPhone, setValidPhone] = useState(true)
  const [enableDelivery, setEnableDelivery] = useLocalStorageState(
    'enableDelivery',
    false
  )
  const {
    choiseYandexCity,
    choiseYandexStreet,
    choiseYandexHouse,
    yandexCity,
    yandexStreet,
    yandexHouse
  } = useYandexCity()
  const [isDeliveryPrice, setIsDeliveryPrice] = useState(false)
  const [email, setEmail] = useLocalStorageState('email', '')
  const dispatch = useDispatch()
  const search = useLocation().search
  const numberTable = new URLSearchParams(search).get('numberTable')
  const person = useSelector((store) => store.user)
  const translation = useTextTranslation()
  // Долгота, широта
  const [coordinates, setCoordinates] = useLocalStorageState<ICoordinates>(
    'yandexCoordinates',
    {
      longitude: 0,
      latitude: 0
    }
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [country, setCountry] = useState<string>('')
  const currentLanguage = useCurrentLanguage()
  const createLink = useLinkWithParams()
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const showAlert = (
    message: string,
    type: 'success' | 'danger' | 'warning'
  ) => {
    dispatch(setNotification({ message, type }))
  }
  const [flagDelivery, setFlagDelivery] = useLocalStorageState(
    'flagDelivery',
    false
  )
  const [selectedOffer, setSelectedOffer] = useLocalStorageState<any>(
    'selectedOffer',
    {}
  )
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [isYookassaPayment, setIsYookassaPayment] = useState(false)
  const border: IBorder = {
    border: `1px ${rest.colorsSchema_id.isOrderDetailBorderLineDotted ? 'dashed' : 'solid'
      } ${rest.colorsSchema_id.borderInputColor}`
  }
  const styleMainColorButton = {
    backgroundColor: rest.colorsSchema_id.buttonColor,
    color: rest.colorsSchema_id.buttonTextColor
  }
  const styleBackgroundColor = {
    background: rest.colorsSchema_id.backgroundColor,
    color: rest.colorsSchema_id.mainTextColor
  }
  const styleLoght = {
    background: rest.colorsSchema_id.backgroundColor,
    border: `1px solid ${rest.colorsSchema_id.borderInputColor}`
  }
  const textColor = {
    color: rest.colorsSchema_id.mainTextColor
  }

  const handleSubmit = (e: FormEvent): void => {
    // Если все валидно, заказ без онлайн оплаты и без параметра numberTable и разрещена отправка sms то
    // вызываем модалку с кодом, иначе если онлайн оплата - то сначала платим или сразу создаем заказ
    e.preventDefault()
    setIsDisableButton(true)
    if (arrayDishesInCart.length > 0) {
      setIsLoading(true)
      if (validPhone) {
        let flagCheckSumDelivery = true
        const resultObjectForOrder: IObjectForCreateOrder = {}
        for (const key of Object.keys(values)) {
          resultObjectForOrder[key] = values[key]
        }
        if (currentCityId.length > 0) {
          rest.city_ids.forEach((city) => {
            if (city._id === currentCityId) {
              if (city.minSummOrder > generalSum) {
                flagCheckSumDelivery = false
                setIsLoading(false)
                setIsDisableButton(false)
                showAlert(
                  `${translation('minimum-order-amount')} ${city.minSummOrder}`,
                  'danger'
                )
              }
            }
          })
          resultObjectForOrder.city = currentCityId
        }
        resultObjectForOrder.email = email
        resultObjectForOrder.street = currentStreet || values.street
        let flagCheckTime = true
        if (flagCheckSumDelivery) {
          if (deliverySum > 0) {
            resultObjectForOrder.deliveryPrice = deliverySum
          }
          const date = new Date()
          const dateOpenTime = new Date()
          const dateCloseTime = new Date()
          if (Boolean(rest.openTime) && Boolean(rest.closeTime)) {
            dateOpenTime.setHours(Number(rest.openTime.split('-')[0]))
            dateOpenTime.setMinutes(Number(rest.openTime.split('-')[1]))

            dateCloseTime.setHours(Number(rest.closeTime.split('-')[0]))
            dateCloseTime.setMinutes(Number(rest.closeTime.split('-')[1]))
          }
          if (check) {
            // выбрано ближайшее время
            if (date > dateCloseTime || date < dateOpenTime) {
              flagCheckTime = false
            } else {
              resultObjectForOrder.datetime = 'yes'
            }
          } else {
            if (resultObjectForOrder.time) {
              const hours = Number(resultObjectForOrder.time.slice(0, 2))
              const minutes = Number(resultObjectForOrder.time.slice(3, 5))
              const dateMiliseconds =
                hours * 60 * 60 * 1000 + minutes * 60 * 1000
              date.setHours(hours)
              date.setMinutes(minutes)
              if (date > dateCloseTime || date < dateOpenTime) {
                flagCheckTime = false
              } else if (startDate != null) {
                resultObjectForOrder.datetime =
                  startDate.getTime() + dateMiliseconds
              }
            } else {
              resultObjectForOrder.datetime = date.getTime()
            }
          }

          if (flagCheckTime) {
            resultObjectForOrder.orderPrice = generalSum
            for (const key of Object.keys(activeMethods)) {
              if (activeMethods[key]) {
                resultObjectForOrder.payment_id = key
              }
            }
            for (const key of Object.keys(activeMethodsOrderType)) {
              if (activeMethodsOrderType[key]) {
                resultObjectForOrder.orderType_id = key
              }
            }
            if (numberTable != null) {
              resultObjectForOrder.numberTable = numberTable
            }
            resultObjectForOrder.rest_id = rest._id
            resultObjectForOrder.check = false
            resultObjectForOrder.orderList = {}
            resultObjectForOrder.orderList.dishes = arrayDishesInCart
            if (enableDelivery) {
              resultObjectForOrder.delivery_detail = {}
              if (resultObjectForOrder.city) {
                resultObjectForOrder.delivery_detail.city =
                  resultObjectForOrder.city
                delete resultObjectForOrder.city
              }
              if (resultObjectForOrder.street) {
                resultObjectForOrder.delivery_detail.street =
                  resultObjectForOrder.street
                delete resultObjectForOrder.street
              }
              if (resultObjectForOrder.house) {
                resultObjectForOrder.delivery_detail.house =
                  resultObjectForOrder.house
                delete resultObjectForOrder.house
              }
              if (resultObjectForOrder.flat) {
                resultObjectForOrder.delivery_detail.flat =
                  resultObjectForOrder.flat
                delete resultObjectForOrder.flat
              }
              if (resultObjectForOrder.porch) {
                resultObjectForOrder.delivery_detail.porch =
                  resultObjectForOrder.porch
                delete resultObjectForOrder.porch
              }
              if (resultObjectForOrder.floor) {
                resultObjectForOrder.delivery_detail.floor =
                  resultObjectForOrder.floor
                delete resultObjectForOrder.floor
              }
              if (isyandexDelivery) {
                resultObjectForOrder.delivery_detail.city = yandexCity
                resultObjectForOrder.delivery_detail.street = yandexStreet
                resultObjectForOrder.delivery_detail.house = yandexHouse
                resultObjectForOrder.delivery_detail.coordinates = coordinates
              }
            }
            if (resultObjectForOrder.userPhone) {
              resultObjectForOrder.userPhone = resultObjectForOrder.userPhone
                .replace(/[()-]/g, '')
                .replace(/\s/g, '')
            }
            if (person.isLoggedIn) {
              resultObjectForOrder.user_id = person._id
            }
            delete resultObjectForOrder.time
            if (rest.customInput_ids) {
              if (rest.customInput_ids.length > 0) {
                resultObjectForOrder.customInputs = []
                rest.customInput_ids.forEach((input) => {
                  resultObjectForOrder.customInputs.push({
                    name: input.name,
                    value: resultObjectForOrder[`${input.name}`]
                  })
                })
              }
            }
            rest.payments_ids.forEach((paymentType) => {
              if (paymentType._id === resultObjectForOrder.payment_id) {
                if (numberTable != null) {
                  resultObjectForOrder.isPaid = false
                  // создаем заказ
                  if (enableDelivery && isyandexDelivery) {
                    delete resultObjectForOrder.datetime
                  }
                  const yandexDelivery = JSON.parse(
                    JSON.stringify(resultObjectForOrder)
                  )
                  const offerData = {
                    offers: [selectedOffer]
                  }
                  yandexDelivery.offerData = offerData
                  if (resultObjectForOrder?.delivery_detail?.floor) {
                    delete yandexDelivery.delivery_detail.floor
                    yandexDelivery.delivery_detail.sfloor =
                      resultObjectForOrder.delivery_detail.floor
                  }
                  if (resultObjectForOrder?.delivery_detail?.house) {
                    delete yandexDelivery.delivery_detal.house
                    yandexDelivery.delivery_detail.building =
                      resultObjectForOrder.delivery_detail.house
                  }
                  if (yandexDelivery) {
                    resultObjectForOrder.offerData = offerData
                  }
                  createOrder('', resultObjectForOrder)
                } else {
                  resultObjectForOrder.isPaid = false
                  if (rest.enableSms && !person.isLoggedIn) {
                    // если пользователь авторизован то нет нужды вызывать sms
                    setObjectForCreateOrder(resultObjectForOrder)
                    setIsLoading(true)
                    setIsCodeInput(true)
                    // вызываем модалку с кодом
                    validatePhoneAPI
                      .sendCode(resultObjectForOrder.userPhone)
                      .then((res) => {
                        setIsLoading(false)
                        setUcallerId(res.ucaller_id)
                      })
                      .catch(() => {
                        setIsLoading(false)
                        setIsDisableButton(false)
                        showAlert(translation('error-send-sms-code'), 'danger')
                      })
                  } else {
                    // создаем заказ
                    if (enableDelivery && isyandexDelivery) {
                      delete resultObjectForOrder.datetime
                    }
                    // eslint-disable-next-line
                    let yandexDelivery: any = {}
                    yandexDelivery.deliveryInfo = JSON.parse(
                      JSON.stringify(resultObjectForOrder)
                    )
                    const offerData = {
                      offers: [selectedOffer]
                    }
                    yandexDelivery.offerData = offerData
                    if (resultObjectForOrder?.delivery_detail?.floor) {
                      delete yandexDelivery.deliveryInfo.delivery_detail.floor
                      yandexDelivery.deliveryInfo.delivery_detail.sfloor =
                        resultObjectForOrder.delivery_detail.floor
                    }
                    if (resultObjectForOrder?.delivery_detail?.house) {
                      delete yandexDelivery.deliveryInfo.delivery_detail.house
                      yandexDelivery.deliveryInfo.delivery_detail.building =
                        resultObjectForOrder.delivery_detail.house
                    }
                    if (yandexDelivery) {
                      resultObjectForOrder.offerData = offerData
                    }

                    createOrder('', resultObjectForOrder)
                  }
                }
              }
            })
          } else {
            setIsLoading(false)
            setIsDisableButton(false)
            showAlert(
              `${translation('we-work-with')} ${rest.openTime} ${translation(
                'before'
              )} ${rest.closeTime}`,
              'danger'
            )
          }
        } else {
          setIsLoading(false)
          setIsDisableButton(false)
          showAlert(translation('small-amount-for-delivery'), 'danger')
        }
      } else {
        setIsLoading(false)
        setIsDisableButton(false)
        showAlert(translation('wrong-phone-number'), 'danger')
      }
    } else {
      setIsLoading(false)
      setIsDisableButton(false)
      showAlert(translation('cart-empty'), 'danger')
    }
  }
  const devidedDeliverySum = deliverySum
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  console.log(devidedDeliverySum)
  const dividedCartSum = cartSum
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  const dividedGeneralSum = generalSum
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

  function toggleActiveMethod(name: string): void {
    const result: IActiveMethod = {}
    for (const key of Object.keys(activeMethods)) {
      result[key] = false
    }
    result[name] = true
    setactiveMethods(result)
  }

  // Сохраняем дату в localStorage
  useEffect(() => {
    if (startDate) {
      localStorage.setItem('startDate', startDate.toISOString())
    }
  }, [startDate])

  // Обновляем email в store и localStorage при изменении email в форме
  useEffect(() => {
    dispatch(updateOrderFormActionCreator({ email }))
  }, [email, dispatch])

  // установили первоначальное значение модалки выбора оффера?
  useEffect(() => {
    setSelectedOffer(offerData ? offerData.offers[0] : '')
  }, [offerData])

  useEffect(() => {
    let activeIdOrderType = ''
    for (const key of Object.keys(activeMethodsOrderType)) {
      if (activeMethodsOrderType[key]) {
        activeIdOrderType = key
      }
    }
    let deliveryEnabled = false
    rest.orderType_ids.forEach((orderType) => {
      if (orderType._id === activeIdOrderType && orderType.isDelivery) {
        deliveryEnabled = true
      }
    })
    setFlagDelivery(deliveryEnabled)
    setEnableDelivery(flagDelivery)
  }, [activeMethodsOrderType, rest.orderType_ids, flagDelivery])

  useEffect(() => {
    if (currentCityId.length > 0) {
      rest.city_ids.forEach((city) => {
        if (city._id === currentCityId) {
          setDeliverySum(city.deliveryPrice)
        }
      })
    }
  }, [currentCityId, rest.city_ids])

  useEffect(() => {
    if (person.isLoggedIn) {
      setValues({
        ...values,
        city: person.city?.title,
        street: person.street,
        house: person.house,
        flat: person.flat,
        porch: person.porch,
        floor: person.floor,
        name: person.name,
        userPhone: person.phone,
        email: person.email
      })
    }
  }, [])

  useEffect(() => {
    if (rest.tariff === 'Pro' || rest.tariff === 'Vip') {
      setHidePayment(false)
    } else {
      setHidePayment(true)
    }
  }, [rest.tariff])

  useEffect(() => {
    const availablePaymentMethods = isyandexDelivery
      ? rest.payments_ids.filter((el) => el.onlineNamePayment !== '')
      : rest.payments_ids

    if (
      Object.keys(activeMethods).length === 0 ||
      (isyandexDelivery &&
        !availablePaymentMethods.some(
          (paymentType) => activeMethods[paymentType._id]
        ))
    ) {
      const initializeActiveMethods = (): void => {
        const result: IActiveMethod = {}

        // Устанавливаем первый доступный метод оплаты для Яндекс доставки как активный
        const firstAvailableMethod =
          availablePaymentMethods.find((paymentType) => paymentType.active) ??
          availablePaymentMethods[0]

        if (firstAvailableMethod) {
          result[firstAvailableMethod._id] = true // Активируем первый метод
        }

        // Отмечаем другие методы как неактивные
        availablePaymentMethods.forEach((paymentType) => {
          if (paymentType._id !== firstAvailableMethod?._id) {
            result[paymentType._id] = false
          }
        })

        setactiveMethods(result)
      }

      initializeActiveMethods()
    }
  }, [activeMethods, isyandexDelivery, rest.payments_ids, setactiveMethods])

  useEffect(() => {
    let totalSum = 0
    arrayDishesInCart.forEach((objectInCart) => {
      let priceForOneDish = 0
      priceForOneDish += objectInCart.dish.price
      if (objectInCart.dish.modifiers_ids.length > 0) {
        objectInCart.dish.modifiers_ids.forEach((modifier) => {
          priceForOneDish += modifier.price
        })
      }
      totalSum += priceForOneDish * objectInCart.quantity
    })
    totalSum = Math.round(totalSum * 100) / 100
    const oldSum = cartSum
    if (totalSum > cartSum) {
      const percent = (totalSum - cartSum) / 100
      for (let i = 1; i < 50; i++) {
        setTimeout(
          setCartSum,
          i * 4,
          Math.round((oldSum + i * 2 * percent) * 100) / 100
        )
      }
    } else if (totalSum < cartSum) {
      for (let i = 1; i < 50; i++) {
        const percent = (cartSum - totalSum) / 100
        setTimeout(
          setCartSum,
          i * 4,
          Math.round((oldSum - i * 2 * percent) * 100) / 100
        )
      }
    }
    setTimeout(setCartSum, 200, totalSum)
  }, [arrayDishesInCart])

  useEffect(() => {
    if (deliverySum !== 0 && isyandexDelivery) {
      setGeneralSum(
        Math.round((Number(deliverySum) + Number(cartSum)) * 100) / 100
      )
    } else {
      setGeneralSum(cartSum)
    }
  }, [cartSum, deliverySum])

  useEffect(() => {
    if (isyandexDelivery && selectedOffer) {
      setDeliverySum(selectedOffer.price.total_price_with_vat)
    } else {
      setDeliverySum(0)
    }
  }, [isyandexDelivery, selectedOffer, setDeliverySum])

  useEffect(() => {
    setPhone(values.userPhone)
  }, [values.userPhone])

  useEffect(() => {
    if (!choiseYandexCity || !choiseYandexStreet || !choiseYandexHouse) {
      setCoordinates({ longitude: 0, latitude: 0 })
      setCountry('')
    }
  }, [choiseYandexCity, choiseYandexStreet, choiseYandexHouse])

  // определение координат
  useEffect(() => {
    if (
      choiseYandexCity &&
      choiseYandexStreet &&
      choiseYandexHouse &&
      arrayDishesInCart.length !== 0
    ) {
      getCoordinates(yandexCity, yandexStreet, yandexHouse)
        .then((data) => {
          const point =
            data.response.GeoObjectCollection.featureMember[0].GeoObject.Point
              .pos
          const geoObject =
            data.response.GeoObjectCollection.featureMember[0].GeoObject
          const country =
            geoObject.metaDataProperty.GeocoderMetaData.AddressDetails.Country
              .CountryName
          setCountry(country)
          const [longitude, latitude] = point.split(' ')
          setCoordinates({
            longitude: Number(longitude),
            latitude: Number(latitude)
          })
        })
        .catch((err) => {
          showAlert(translation('error-determining-coordinates'), 'danger')
          console.error(err)
        })
    }
  }, [
    choiseYandexCity,
    choiseYandexStreet,
    choiseYandexHouse,
    arrayDishesInCart
  ])

  useEffect(() => {
    if (
      enableDelivery &&
      choiseYandexCity &&
      choiseYandexStreet &&
      choiseYandexHouse &&
      isDeliveryPrice
    ) {
      setIsDisableButton(false)
    } else if (!enableDelivery) {
      setIsDisableButton(false)
    } else if (enableDelivery && !isyandexDelivery) {
      setIsDisableButton(false)
    } else if (selectedOffer !== '') {
      setIsDisableButton(false)
    } else {
      setIsDisableButton(true)
    }
  }, [
    enableDelivery,
    choiseYandexCity,
    choiseYandexStreet,
    choiseYandexHouse,
    isDeliveryPrice,
    selectedOffer,
    isyandexDelivery
  ])

  useEffect(() => {
    if (enableDelivery) {
      if (yandexCity === '' || yandexStreet === '' || yandexHouse === '') {
        setIsDisableButton(true)
      }
    }
  }, [yandexCity, yandexStreet, yandexHouse])

  useEffect(() => {
    if (choiseYandexCity && choiseYandexStreet && choiseYandexHouse) {
      if (
        coordinates.latitude === 0 &&
        coordinates.longitude === 0 &&
        arrayDishesInCart.length === 0
      ) {
        showAlert(translation('cart-empty-for-delivery'), 'danger')
      } else if (
        coordinates.latitude !== 0 &&
        coordinates.longitude !== 0 &&
        arrayDishesInCart.length > 0
      ) {
        const resultObjectForOrder: any = {}
        resultObjectForOrder.orderList = {}
        resultObjectForOrder.orderList.dishes = arrayDishesInCart
        resultObjectForOrder.delivery_detail = {}
        resultObjectForOrder.delivery_detail.city = yandexCity
        resultObjectForOrder.delivery_detail.street = yandexStreet
        resultObjectForOrder.delivery_detail.building = yandexHouse
        resultObjectForOrder.delivery_detail.coordinates = coordinates
        resultObjectForOrder.rest_id = rest._id
        getDeliveryPrice(resultObjectForOrder)
          .then((data: any) => {
            setOfferData(data)
            setOpenModal(true)
            const deliverySum = data.offers[0].price.total_price_with_vat
            setDeliverySum(deliverySum)
            setIsDeliveryPrice(true)
          })
          .catch((err) => {
            showAlert(
              translation('error-when-determining-the-delivery-amount'),
              'danger'
            )
            console.error(err)
          })
      }
    }
  }, [
    choiseYandexCity,
    choiseYandexStreet,
    choiseYandexHouse,
    coordinates.latitude,
    coordinates.longitude,
    arrayDishesInCart
  ])

  useEffect(() => {
    // Проверка если есть активный метод оплаты Юкасса то нужно показывать инпут с электронной почтой
    const yookassapayment = rest.payments_ids.find((payment) => payment.onlineNamePayment === 'Юкасса')
    if (yookassapayment) {
      if (activeMethods[yookassapayment._id]) {
        setIsYookassaPayment(true)
      } else {
        setIsYookassaPayment(false)
      }
    }
  }, [activeMethods])
  return (
    <>
      {isLoading ? (
        <CustomSpinner rest={rest} />
      ) : (
        <form
          className={style['cart-detail']}
          style={styleBackgroundColor}
          onSubmit={handleSubmit}
        >
          {!wantOrder ? (
            <>
              <div className={style['cart-detail__title']}>
                {translation('order-details')}
              </div>
              <div className={style['cart-detail__params']}>
                {isRemoteOrder ? (
                  <>
                    <InputName
                      value={values.name || ''}
                      handleChange={handleChange}
                      border={border}
                      rest={rest}
                    />
                    <div style={{ marginTop: '10px' }}>
                      <InputPhone
                        rest={rest}
                        value={values.userPhone}
                        handleChange={handleChange}
                        setValidPhone={setValidPhone}
                        border={border}
                      />
                    </div>
                    {(isyandexDelivery || isYookassaPayment) && (
                      <div style={{ marginTop: '10px' }}>
                        <InputEmail
                          email={email}
                          setEmail={setEmail}
                          border={border}
                          rest={rest}
                        />
                      </div>
                    )}
                    {rest.customInput_ids
                      ? rest.customInput_ids.length > 0
                        ? rest.customInput_ids.map((customInput, index) => (
                          <div key={index} style={{ marginTop: '10px' }}>
                            <CustomInput
                              border={border}
                              key={customInput._id}
                              input={customInput}
                              handleChange={handleChange}
                              value={values[`${customInput.name}`] || ''}
                              rest={rest}
                            />
                          </div>
                        ))
                        : ''
                      : ''}
                    {enableDelivery ? (
                      <>
                        <div style={{ marginTop: '10px' }}>
                          <CartAdressBlock
                            rest={rest}
                            isRemoteOrder={isRemoteOrder}
                            currentCityId={currentCityId}
                            setCurrentCityId={setCurrentCityId}
                            currentStreet={currentStreet}
                            setCurrentStreet={setCurrentStreet}
                            handleChange={handleChange}
                            city={values.city || ''}
                            street={values.street || ''}
                            house={values.house || ''}
                            flat={values.flat || ''}
                            porch={values.porch || ''}
                            floor={values.floor || ''}
                            border={border}
                            setIsDeliveryPrice={setIsDeliveryPrice}
                            yandexDelivery={isyandexDelivery}
                          />
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                    {!enableDelivery ||
                      (enableDelivery && !isyandexDelivery) ? (
                      <div style={{ marginTop: '10px' }}>
                        <CartTimeBlock
                          startDate={startDate}
                          setStartDate={setStartDate}
                          enableDateDelivery={rest.enableDateDelivery}
                          check={check} // если true то "Ближайщее время", если false то выбран промежуток времени
                          setCheck={setCheck}
                          handleChange={handleChange}
                          valueTime={values.time || ''} // время доставки
                          border={border}
                          rest={rest}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                    <div style={{ marginTop: '10px' }}>
                      <div className={style['input-wrapper']}>
                        <label
                          style={{
                            backgroundColor: `${rest.colorsSchema_id.backgroundColor}`,
                            color: `${rest.colorsSchema_id.mainTextColor}`
                          }}
                          className={style['cart-detail__subtitle']}
                        >
                          {translation('comment')}
                        </label>
                        <TextareaComment
                          value={values.comment || ''}
                          handleChange={handleChange}
                          border={border}
                          rest={rest}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={style['cart-detail__subtitle']}>
                      {translation('comment')}
                    </div>
                    <TextareaComment
                      value={values.comment || ''}
                      handleChange={handleChange}
                      border={border}
                      rest={rest}
                    />
                  </>
                )}
              </div>
            </>
          ) : (
            ''
          )}
          <div className={style['cart-detail__general']} style={styleLoght}>
            {!wantOrder ? (
              <>
                <div className={style['cart-detail__general-summ']}>
                  <div
                    className={style['cart-detail__general-summ-title']}
                    style={textColor}
                  >
                    <span style={{ fontSize: '20px' }}>
                      {translation('order-amount')}
                    </span>
                  </div>
                  <div className={style['cart-detail__general-summ-value']}>
                    <span style={{ fontSize: '16px' }}>
                      {dividedCartSum}&nbsp;{rest.currentCurrency}
                    </span>
                  </div>
                </div>
                {isRemoteOrder && enableDelivery && devidedDeliverySum !== '0' && (
                  <div className={style['cart-detail__general-delivery']}>
                    <div
                      className={style['cart-detail__general-delivery-title']}
                      style={textColor}
                    >
                      {translation('delivery')}
                    </div>
                    <div
                      className={style['cart-detail__general-delivery-value']}
                    >
                      {/* финальная цена доставки */}
                      {devidedDeliverySum}&nbsp;{rest.currentCurrency}
                    </div>
                  </div>
                )}
              </>
            ) : (
              ''
            )}
            <div className={style['cart-detail__general-final']}>
              <div
                className={style['cart-detail__general-final-title']}
                style={textColor}
              >
                <span style={{ fontSize: '20px' }}>
                  {translation('to-be-paid')}
                </span>
              </div>
              <div className={style['cart-detail__general-final-value']}>
                <span style={{ fontSize: '18px' }}>
                  {dividedGeneralSum}&nbsp;{rest.currentCurrency}
                </span>
              </div>
            </div>
            {rest.tariff !== 'Extend' && (
              <div className={style['cart-detail__general-final-agreement']}>
                <div className={style['cart-detail__agreement']}>
                  <p className={style['cart-detail__agreement__p']}>
                    {translation('by-clicking-the-order-button-you')}{' '}
                    <Link
                      style={textColor}
                      className={style['cart-detail__agreement__a']}
                      to={createLink('/pd')}
                    >
                      {translation(
                        'consent-to-the-processing-of-your-personal-data'
                      )}
                    </Link>
                  </p>
                </div>
              </div>
            )}
          </div>
          {!hidePayment ? (
            <div className={style['cart-delivery-block']} style={styleLoght}>
              {rest.payments_ids.map(
                (paymentType) =>
                  // НУЖНО Изменить логику для мультиязычности.
                  paymentType.active && (
                    <CartDeliveryBlock
                      title={
                        isRemoteOrder
                          ? paymentType.title[currentLanguage]!
                          : paymentType.title.RU === 'Картой при получении'
                            ? 'Картой официанту'
                            : paymentType.title.RU === 'Наличными при получении'
                              ? 'Наличными официанту'
                              : paymentType.title[currentLanguage]!
                      }
                      active={activeMethods[paymentType._id]}
                      rest={rest}
                      nameActiveMethod={paymentType._id}
                      toggleActiveMethod={toggleActiveMethod}
                      key={paymentType._id}
                      yandexDelivery={isyandexDelivery}
                      paymentType={paymentType}
                    />
                  )
              )}
            </div>
          ) : (
            ''
          )}
          {wantOrder ? (
            <div className={style['cart-detail__order-description']}>
              {translation('for-order-write-list-dishes-waiter')}
            </div>
          ) : (
            ''
          )}
          {!wantOrder ? (
            <div className={style['cart-button']}>
              <button
                className={style.btn}
                style={styleMainColorButton}
                type='submit'
                disabled={isDisableButton}
              >
                {translation('to-order')}
              </button>
            </div>
          ) : (
            ''
          )}
        </form>
      )}
      {openModal && offerData && offerData?.offers.length > 1 && (
        <ModalSelectOffers
          offerData={offerData}
          setSelectedOffer={setSelectedOffer}
          selectedOffer={selectedOffer}
          rest={rest}
          setOpenModal={setOpenModal}
        />
      )}
    </>
  )
}
export default CartDetali
